import * as React from "react"
import Layout from "../components/Layout/Layout"
import styled from "styled-components"
import Button from "../components/Button/Button"

const Container = styled.section`
    width: min(90%, 1000px);
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: var(--space-11);
`

function Success() {
    return (
        <Layout pageTitle="Success">
            <Container>
                <h1>Thanks for your message.</h1>
                <p>It's been sent successfully so keep an eye on your inbox - I'll get back to you as soon as possible.</p>
                <Button link={true} linkTo="/">Go back home</Button>
            </Container>
        </Layout>
    )
}

export default Success